var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "py-1"
  }, [_c('h4', {
    staticClass: "card-title font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('filters')) + " ")]), _c('div', {
    staticClass: "d-flex-center justify-content-end gap-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "warning",
      "size": "sm"
    },
    on: {
      "click": _vm.onUpdateFilters
    }
  }, [_c('HStack', {
    staticClass: "text-nowrap",
    attrs: {
      "center": ""
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "SearchIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('search')) + " ")], 1)], 1), _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "danger"
    },
    on: {
      "click": _vm.onClearFilters
    }
  }, [_c('HStack', {
    staticClass: "text-nowrap",
    attrs: {
      "center": ""
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "XOctagonIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('clear')) + " ")], 1)], 1)], 1)]), _c('b-card-body', {
    staticClass: "py-0"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('topup.startDate')
    }
  }, [_c('b-form-datepicker', {
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "today-button": !_vm.endDate,
      "reset-button": "",
      "close-button": "",
      "locale": _vm.$i18n.locale,
      "max": _vm.maxStartDate,
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "date-format-options": {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
    },
    model: {
      value: _vm.startDate,
      callback: function callback($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('topup.endDate')
    }
  }, [_c('b-form-datepicker', {
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "today-button": "",
      "reset-button": "",
      "close-button": "",
      "locale": _vm.$i18n.locale,
      "min": _vm.minEndDate,
      "max": _vm.today,
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "date-format-options": {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
    },
    model: {
      value: _vm.endDate,
      callback: function callback($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('agency.agencyCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('saleReport.phAgencyCode')
    },
    model: {
      value: _vm.agencyCode,
      callback: function callback($$v) {
        _vm.agencyCode = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "agencyCode"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('saleReport.columns.flightType')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.flightTypeOptions,
      "label": "label",
      "clearable": false,
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.value;
      },
      "disabled": Boolean(_vm.fixFlightType)
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:flightTypeFilter', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
      }
    }]),
    model: {
      value: _vm.flightType,
      callback: function callback($$v) {
        _vm.flightType = $$v;
      },
      expression: "flightType"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('saleReport.columns.bookingCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('saleReport.phBookingCode')
    },
    model: {
      value: _vm.bookingCode,
      callback: function callback($$v) {
        _vm.bookingCode = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "bookingCode"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('saleReport.columns.ticketNumber')
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('saleReport.phTicketNumber')
    },
    model: {
      value: _vm.ticketNumber,
      callback: function callback($$v) {
        _vm.ticketNumber = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "ticketNumber"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }