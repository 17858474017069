<template>
  <div>
    <!-- ANCHOR Filters -->
    <SaleReportRefundTicketListFilters
      ref="saleReportListFilters"
      :fix-flight-type="fixFlightType"
      :search-text.sync="searchText"
      @refetch-data-with-filters="refetchData"
      @refetch-data-without-filters="clearFilter"
    />

    <!-- Table Container Card -->
    <b-card
      style="max-height: 70vh"
      class=""
      no-body
    >
      <!-- SECTION Table Top -->
      <div class="m-1">
        <div class="d-flex justify-content-between">
          <!-- ANCHOR Per Page -->
          <div
            md="auto"
            class="px-0 mr-md-1"
          >
            <v-select
              v-model="sizePerPage"
              :options="sizePerPageLgOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <!-- !SECTION -->

      <!-- SECTION Table content -->
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <b-table
          ref="refSaleReportRefundTicketListTable"
          style="max-height: 50vh"
          sticky-header
          class="position-relative"
          :items="fetchRefundTicket"
          responsive
          bordered
          :fields="tableColumns"
          primary-key="id"
          table-class="table-sales-report"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('noRecordFund')"
          :sort-desc.sync="isSortDirDesc"
          no-border-collapse
        >
          <!-- ANCHOR Header -->
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]="data"
          >
            <div
              :key="column.label"
              class="text-dark text-nowrap text-center"
            >
              {{ $t(`saleReport.columns.${data.label}`) }}
            </div>
          </template>

          <template
            v-for="column in tableColumns"
            #[`cell(${column.key})`]="{ item }"
          >
            <div
              :key="column.key"
              class="text-nowrap"
              :class="{
                'text-right': typeof item[column.key] === 'number',
              }"
            >
              {{ item[column.key] }}
            </div>
          </template>

          <!-- ANCHOR no. -->
          <template #cell(no)="data">
            <div class="mb-0 text-nowrap">
              {{ data.index + dataMeta.from }}
            </div>
          </template>

          <!-- ANCHOR bookingCode -->
          <template #cell(bookingCode)="{ item: { bookingCode, bookingId } }">
            <b-link
              v-if="bookingId"
              :to="{ name: 'apps-reservations-modify', params: { id: bookingId } }"
              class="font-weight-bolder"
            >
              {{ bookingCode }}
            </b-link>
            <span
              v-else
              class="font-weight-bolder text-dark"
            >{{ bookingCode }}</span>
          </template>

          <!-- ANCHOR issueDate -->
          <template #cell(issueDate)="{ item: { issueDate } }">
            {{ convertISODateTime(issueDate).timeDate }}
          </template>

          <!-- ANCHOR bookingDate -->
          <template #cell(bookingDate)="{ item }">
            <div class="text-nowrap">
              {{ convertISODateTime(item.bookingDate).timeDate }}
            </div>
          </template>

          <!-- ANCHOR priceTicket -->
          <template #cell(priceTicket)="{ item: { priceTicket } }">
            <div class="text-right text-nowrap">
              {{ formatVnCurrency(priceTicket) }}
            </div>
          </template>

          <!-- ANCHOR collection fee -->
          <template #cell(collectionFee)="{ item: { collectionFee } }">
            <div class="text-right text-nowrap">
              {{ formatVnCurrency(collectionFee) }}
            </div>
          </template>

          <!-- ANCHOR tax -->
          <template #cell(tax)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.tax) }}
            </div>
          </template>

          <!-- ANCHOR fee -->
          <template #cell(fee)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.fee) }}
            </div>
          </template>

          <!-- ANCHOR feeIn -->
          <template #cell(feeIn)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.feeIn) }}
            </div>
          </template>

          <!-- ANCHOR feeOut -->
          <template #cell(feeOut)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.feeOut) }}
            </div>
          </template>

          <!-- ANCHOR feeService -->
          <template #cell(feeService)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.feeService) }}
            </div>
          </template>

          <!-- ANCHOR commissionPrepaid -->
          <template #cell(commissionPrepaid)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.commissionPrepaid) }}
            </div>
          </template>
          <!-- ANCHOR commissionPrepaidIn -->
          <template #cell(commissionPrepaidIn)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.commissionPrepaidIn) }}
            </div>
          </template>
          <!-- ANCHOR commissionPrepaidOut -->
          <template #cell(commissionPrepaidOut)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.commissionPrepaidOut) }}
            </div>
          </template>

          <!-- ANCHOR unpaidCommission -->
          <template #cell(unpaidCommission)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.unpaidCommission) }}
            </div>
          </template>

          <!-- ANCHOR tradeCreditors -->
          <template #cell(tradeCreditors)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.tradeCreditors) }}
            </div>
          </template>

          <!-- ANCHOR receivables -->
          <template #cell(receivables)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.receivables) }}
            </div>
          </template>

          <!-- ANCHOR profit -->
          <template #cell(profit)="{ item }">
            <div class="text-right">
              {{ formatVnCurrency(item.receivables - item.tradeCreditors) }}
            </div>
          </template>

          <!-- ANCHOR totalPayment -->
          <template #cell(totalPayment)="{ item: { totalPayment } }">
            <div class="font-weight-bold text-right">
              {{ formatVnCurrency(totalPayment) }}
            </div>
          </template>

          <!-- ANCHOR createdAt -->
          <template #cell(createdAt)="data">
            <div class="mb-0 text-nowrap">
              {{ dateTime(data.item.createdAt) }}
            </div>
          </template>

          <!-- ANCHOR updatedAt -->
          <template #cell(updatedAt)="data">
            <div class="mb-0 text-nowrap">
              {{ dateTime(data.item.updatedAt) }}
            </div>
          </template>
          <!-- ANCHOR agency -->
          <template #cell(agency)="data">
            <div class="mb-0 text-nowrap">
              {{ data.item.agency?.agencyCode.toUpperCase() }}
            </div>
          </template>

          <template #cell(action)="{ item }">
            <div class="d-flex gap-1">
              <b-button
                v-if="!item.markAsUsed"
                name="handleShowModalResell"
                variant="danger"
                size="sm"
                class="text-nowrap p-50"
                @click="handleShowModalResell(item)"
              >
                {{ $t("resellTicket.sell") }}
              </b-button>
              <b-button
                v-if="item.markAsUsed && item.resellTicketStatus != 'SOLD'"
                name="handleShowModalResell"
                variant="info"
                size="sm"
                class="text-nowrap p-50"
              >
                {{ $t("resellTicket.posted") }}
              </b-button>
              <b-button
                v-if="item.markAsUsed && item.resellTicketStatus === 'SOLD'"
                variant="success"
                size="sm"
                class="text-nowrap p-50"
              >
                {{ $t("resellTicket.status.SOLD") }}
              </b-button>
              <b-button
                v-if="item.resellTicketId && item.markAsUsed"
                name="handleShowModalEditNote"
                variant="primary"
                size="sm"
                class="text-nowrap p-50"
                @click="handleShowModalEditNote(item)"
              >
                {{ $t("resellTicket.btn.editNote") }}
              </b-button>
            </div>
          </template>
        </b-table>
      </b-overlay>
      <!-- !SECTION -->

      <!-- ANCHOR: Table Footer -->
      <div class="mx-2 mb-1">
        <b-row>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-center"
          >
            <span class="text-muted">
              {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
              {{ dataMeta.of }} {{ $t('paginationText.items') }}
            </span>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="sizePerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <ResellTicketModal
      :sell-ticket="sellTicket"
      @refresh="refetchData"
    />

    <NoteModal
      :ticket.sync="modifyNote"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  computed,
  onUnmounted,
  ref,
} from '@vue/composition-api'
import {
  BButton,
  BCard,
  BCol,
  BLink,
  BOverlay,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import env from '@/libs/env'
import { SALE_REPORT_REFUND_TICKET_TABLE_COLUMNS } from '@/constants/saleReport'
import {
  sizePerPageLgOptions,
} from '@/constants/selectOptions'
import store from '@/store'
import {
  formatCurrency,
  resolveFightTypeFromTicketType,
} from '@/views/apps/invoices/useInvoice'

import {
  convertISODateTime,
  dateTime,
  formatVnCurrency,
  getDate,
} from '@core/utils/filter'

import saleReportStoreModule from '@saleReport/saleReportStoreModule'
import useSaleReportHandle from '@saleReport/useSaleReportHandle'

import SaleReportRefundTicketListFilters from './SaleReportRefundTicketListFilters.vue'

export default {
  components: {
    SaleReportRefundTicketListFilters,
    BCard,
    BRow,
    BCol,
    BOverlay,
    BTable,
    BPagination,
    BLink,
    BButton,
    vSelect,
    ResellTicketModal: () => import('@saleReport/resell-ticket/ResellTicketModal.vue'),
    NoteModal: () => import('@saleReport/refund-ticket/NoteModal.vue'),
  },

  setup() {
    const SALE_REPORT_APP_STORE_MODULE_NAME = 'app-sale-report'

    // Register module
    if (!store.hasModule(SALE_REPORT_APP_STORE_MODULE_NAME)) {
      store.registerModule(SALE_REPORT_APP_STORE_MODULE_NAME, saleReportStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SALE_REPORT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(SALE_REPORT_APP_STORE_MODULE_NAME)
      }
    })

    const roleMama = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    const tableColumns = computed(() => {
      const columns = SALE_REPORT_REFUND_TICKET_TABLE_COLUMNS.filter(i => env.isHideResell ? !['action', 'notePrivate'].includes(i.key) : true)

      if (roleMama.value) {
        return columns.filter(item => !['tradeCreditors'].includes(item.key))
      }
      const removeColumns = ['collectionFee', 'tax']
      if (isRoleF3.value) removeColumns.push('action')
      return columns.filter(item => !removeColumns.includes(item.key))
    })

    function confirmExport() {
      // show modal confirm export sale report
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmExport') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.exportSaleReport()
          }
        })
    }

    const {
      // Loading
      loading,
      // Refs
      refSaleReportListTable: refSaleReportRefundTicketListTable,

      // Filters
      sizePerPage,
      currentPage,
      dataMeta,
      sortBy,
      isSortDirDesc,
      fixFlightType,
      // Extra Filters
      searchText,

      totalItems,
      saleReportItems,

      refetchData,
      clearFilter,

      // DISPATCH STORE
      fetchRefundTicket,
      exportSaleReport,
    } = useSaleReportHandle()

    const sellTicket = ref({})
    const modifyNote = ref({})
    function handleShowModalResell(item) {
      sellTicket.value = item
      this.$bvModal.show('create-resell-ticket-modal')
    }
    function handleShowModalEditNote(item) {
      modifyNote.value = item
      modifyNote.value.id = item.resellTicketId
      this.$bvModal.show('modify-note-modal')
    }
    return {
      tableColumns,
      // SECTION from import
      dateTime,
      getDate,
      convertISODateTime,

      // select Options
      sizePerPageLgOptions,
      // !SECTION from import

      // SECTION useSaleReportHandle()
      // Loading
      loading,
      // Refs
      refSaleReportRefundTicketListTable,

      // Filters
      sizePerPage,
      currentPage,
      dataMeta,
      sortBy,
      isSortDirDesc,
      fixFlightType,
      // Extra Filters
      searchText,

      totalItems,
      saleReportItems,

      refetchData,
      clearFilter,

      // DISPATCH STORE
      fetchRefundTicket,
      exportSaleReport,
      // !SECTION useSaleReportHandle()

      // SECTION setup()
      confirmExport,
      handleShowModalResell,
      handleShowModalEditNote,
      formatVnCurrency,
      // !SECTION setup()

      formatCurrency,
      sellTicket,
      modifyNote,
    }
  },

  computed: {
    ticketTypeFilter() {
      if (!this.$store.hasModule('app-invoice')) return ''
      return this.$store.state['app-invoice'].ticketTypeFilter
    },

    isAlreadySelectAll() {
      if (!this.previousSelectedTicketIds.length) return false

      return this.idsList.every(id => this.previousSelectedTicketIds.includes(id))
    },
    disabledSelectAll() {
      return this.isAlreadySelectAll || !this.maxSelectionCount
    },
    isSelectAll() {
      if (this.isAlreadySelectAll) return true
      if (!this.maxSelectionCount || this.maxSelectionCount !== this.selectionCount) return false
      return this.idsCanSelect.every(id => this.selectedIds.includes(id))
    },
    canSelectMore() {
      return this.maxSelectionCount && this.maxSelectionCount > this.selectionCount
    },

    showCheckboxSelectAll() {
      if (this.isSelectAll) return true
      if (this.notSelect && this.maxSelectionCount && this.maxSelectionCount === this.idsList.length) return true
      return !this.canSelectMore || (this.notSelect && !this.previousSelectedTicketIds.length)
    },
  },

  watch: {
    ticketTypeFilter: {
      handler(val) {
        this.fixFlightType = resolveFightTypeFromTicketType(val)
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.table-sales-report {
  th {
    padding-left: 6px;
    padding-right: 6px;
  }

  td {
    font-size: 14px;
    padding: 6px !important;
  }
}
</style>
